<template>
  <b-container>
    <b-row>
      <b-col>
        <p class="h2 pb-2 pt-4 text-center">
          Masz pytania? Skontaktuj się z nami!
        </p>
      </b-col>
    </b-row>

    <div class="row pt-4">
      <div class="cols-xs-12 col-md-8">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4995.437987784856!2d22.513413270321838!3d51.2426699442814!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4542446a82119787!2sMS+Konsulting+ubezpieczeniamsk.com!5e0!3m2!1sen!2spl!4v1503792838902"
          style="min-width: 100%; min-height: 70vh"
          allowfullscreen=""
        ></iframe>
      </div>
      <div class="cols-xs-12 col-md-4 text-center contact-text">
        <p class="">
          <b-icon-telephone-fill class="mr-2" /><span itemprop="telephone"
            >784 571 335</span
          >
        </p>
        <p class="">
          <b-icon-telephone-fill class="mr-2" /><span itemprop="telephone"
            >666 200 505</span
          >
        </p>

        <p>
          <EmailDisplay />
        </p>

        <p class="">
          <b-icon-house-fill class="mr-2" />Lublin, ul. Wileńska 21 (pawilony),
          LSM
        </p>

        <p class="">
          Dostępne parkingi:<br />
          - przy pawilonach<br />
          - pod CH Leclerc (Zana)<br />
          - wzdłuż ul. Zana i ul. Krasińskiego
        </p>
      </div>
    </div></b-container
  >
</template>

<script>
import EmailDisplay from "@/components/EmailDisplay.vue";
export default {
  components: { EmailDisplay },
  name: "Contact",
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}

.contact-text {
  font-size: 1.2rem;
}
</style>
